import React from 'react';
import { Link } from 'gatsby';

const About = () => (
  <React.Fragment>
    <div>About page</div>
    <Link to="/">Back to home</Link>
  </React.Fragment>
)

export default About